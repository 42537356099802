import {Link, useLocation} from "react-router-dom";
import {useRef, useState, useEffect} from "react";
import NavLogo from "../../Assets/Logo/ROCK BOTTOM + SLOGAN/ROCK BOTTOM + SLOGAN NO BACKGROUND.png"
import {gsap} from "gsap";
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';

const Navbar = () => {

    const { pathname } = useLocation();
    const navLogo = useRef(null);
    const mobileNav = useRef(null);
    const [isHovered, setIsHovered] = useState(true)
    const [open, setOpen] = useState(true);
    const hoverTimeline = gsap.timeline()
    const mobileNavTimeline = gsap.timeline();
    const [isMobile, setMobile] = useState(window.innerWidth < 991);

    const updateMedia = () => {
        setMobile(window.innerWidth < 991);
    };

    useEffect(() => {
        window.addEventListener("resize", updateMedia);
        return () => window.removeEventListener("resize", updateMedia);
    });

    const navLogoHover = () => {
        setIsHovered(!isHovered);
        isHovered ?
            hoverTimeline
                .to([navLogo.current], {
                    scale: 1.05,
                    duration: 0.3
                })
            :
            hoverTimeline
                .to([navLogo.current], {
                    scale: 1,
                    duration: 0.3
                })
    }

    const toggleMobileMenu = () => {
        setOpen(!open);
        open ?
            mobileNavTimeline
                .to([mobileNav.current], {
                    height: "auto",
                    visibility: "visible",
                    opacity: 1,
                    duration: 0.3
                })
            :
            mobileNavTimeline
                .to([mobileNav.current], {
                    height: 0,
                    opacity: 0,
                    duration: 0.3
                })
                .to([mobileNav.current], {
                    visibility: "hidden",
                    duration: 0.1
                });

    }


    return(
        <nav id={"navbar"} className={pathname === '/' ? "absolute" : "relative"} data-draggable="">
            <div id={"nav-left"}>
                <Link to={"/"} id={"nav-logo-container"}>
                    {isMobile ?
                        <img ref={navLogo} id={"nav-logo"} className={"nav-link mobile-nav-home"} onMouseEnter={navLogoHover} onMouseLeave={navLogoHover} src={NavLogo} alt={"Nav logo"}/>
                        :
                        <img ref={navLogo} id={"nav-logo"} className={"nav-link nav-logo"} onMouseEnter={navLogoHover} onMouseLeave={navLogoHover} src={NavLogo} alt={"Navbar logo"}/>
                    }
                </Link>
            </div>
            {open ? <MenuIcon className={"mobile-nav"} onClick={toggleMobileMenu}/> : <CloseIcon className={"mobile-nav"} onClick={toggleMobileMenu}/>}
            <div ref={mobileNav} id={"nav-right"} className={isMobile && pathname === '/' && "mobile-nav-home-page"}>
                <Link to={"/about"} className={"nav-link mobile-nav-link"}>About</Link>
                <Link to={"/band-members"} className={"nav-link mobile-nav-link"}>Bandmembers</Link>
                <Link to={"/events"} className={"nav-link mobile-nav-link"}>Events</Link>
                <Link to={"/gallery"} className={"nav-link mobile-nav-link"}>Gallery</Link>
                <Link to={"/listen"} className={"nav-link mobile-nav-link"}>Listen</Link>
                <Link to={"/contact"} className={"nav-link mobile-nav-link"}>Contact</Link>
            </div>
        </nav>
    )

}

export default Navbar;