import Navbar from "../../Components/Navbar/Navbar";
import YoutubeEmbed from "../../Components/YoutubeEmbed/YoutubeEmbed";
import Footer from "../../Components/Footer/Footer";
import ListenHeader from "../../Assets/Titles/Pages/07-LISTEN.png";

const Listen = () => {

    return (
        <div className={"page"}>
            <Navbar />
            <div id={"listen-page"}>
                <div id={"listen-page-banner-container"}>
                    <img id={"listen-page-banner"} src={ListenHeader} alt={"Events page header"}/>
                </div>
                <YoutubeEmbed embedId={"tZmNoC7fYSo"} />
            </div>
            <Footer />
        </div>
    )
}

export default Listen