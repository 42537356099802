import Navbar from "../../Components/Navbar/Navbar";
import Footer from "../../Components/Footer/Footer";
import Button from "../../Components/Button/Button";
import ContactImg from "../../Assets/Titles/Pages/06-CONTACT.png"

const Contact = () => {
    return (
        <div className={"page"}>
            <Navbar />
            <div id={"contact-page"}>
                <div id={"contact-header-container"}>
                    <img className={"contact-page-header"} src={ContactImg} alt={"Page header"}/>
                </div>
                <div id={"contact-buttons-container"}>
                    <Button content={<a href="mailto:info@rockbottomband.nl">E-mail</a>} />
                    <Button content={<a href="https://www.instagram.com/rockbottom.band/" target={"_blank"} rel={"noreferrer"}>Instagram</a>} />
                    <Button content={<a href="https://www.facebook.com/coverbandrockbottom" target={"_blank"} rel={"noreferrer"}>Facebook</a>} />
                    <Button content={<a href="https://www.youtube.com/channel/UC5XCt50xW1yBDuIKKPmORYw" target={"_blank"} rel={"noreferrer"}>Youtube</a>} />
                </div>

            </div>
            <Footer />
        </div>
    )
}

export default Contact;