import BandMemberBio from "../../Components/BandMemberBio/BandMemberBio";
import Navbar from "../../Components/Navbar/Navbar";
import Footer from "../../Components/Footer/Footer";
import ToTop from "../../Components/ToTop/ToTop";
import Jackie from "../../Assets/BandMembers/01-Jackie.jpeg"
import JackieName from "../../Assets/Titles/Names/01-JACKIELOU.png"
import JackieSig from "../../Assets/BandMembers/SIGNATURES/W_OUT BACKGROUND/Jackie Lou.png"
import Rasmus from "../../Assets/BandMembers/02-Rasmus.jpeg"
import RasmusName from "../../Assets/Titles/Names/02-RASMUSBISSCHOP.png"
import RasmusSig from "../../Assets/BandMembers/SIGNATURES/W_OUT BACKGROUND/Rasmus Bisschop.png"
import Mathijs from "../../Assets/BandMembers/03-Mathijs.jpeg"
import MathijsName from "../../Assets/Titles/Names/03-MATHIJSVANDERVOORN.png"
import MathijsSig from "../../Assets/BandMembers/SIGNATURES/W_OUT BACKGROUND/Mathijs van der Voorn.png"
import Esther from "../../Assets/BandMembers/04-Esther.jpeg"
import EstherName from "../../Assets/Titles/Names/04-ESTHERJACOBS.png"
import EstherSig from "../../Assets/BandMembers/SIGNATURES/W_OUT BACKGROUND/Esther Jacobs.png"
import Olaf from "../../Assets/BandMembers/05-Olaf.jpeg"
import OlafName from "../../Assets/Titles/Names/05-OLAFZEGEREN.png"
import OlafSig from "../../Assets/BandMembers/SIGNATURES/W_OUT BACKGROUND/Olaf Zegeren.png"
import Renzo from "../../Assets/BandMembers/06-Renzo.jpeg"
import RenzoName from "../../Assets/Titles/Names/06-RENZORIJN.png"
import RenzoSig from "../../Assets/BandMembers/SIGNATURES/W_OUT BACKGROUND/Renzo Rijn.png"

const BandMembers = () => {

    const memberInfo = [
        {
            name: JackieName,
            text:
                <div className={"member-text"}>
                    <h2 className={"q"}>So Jackie, Rock Bottom is a brand new band, how did you guys meet?</h2>
                    <p className={"a"}>You could say that we met through Instagram haha. I'd been looking for a band for some time and we just clicked. Musically as well as idiotically.</p>
                    <h2 className={"q"}>Now this project seems right down your alley. What's it inspired by?</h2>
                    <p className={"a"}>I've been a bit of a lost rockstar myself, only without the good part where one actually has a band. 😂 These days I'm less of a troublemaker, but all the more I live for and through the music. This project is the result of that inner change.</p>
                    <h2 className={"q"}>You have been a professional singer for quite some time now. Tell us about something that happened backstage and stayed backstage?</h2>
                    <p className={"a"}>One time I played in Paradiso with Duncan Idaho as the supporting act for San Cisco (AUS). The show went great and afterwards we got ourselves completely shitfaced backstage. That night ended with butts, balls and faces on the copying machine and the prints ended up in the physical mailboxes of the employees of Paradiso. I still wonder if they thought it was funny haha!</p>
                    <h2 className={"q"}>Classic. And something else then, what is your favorite Rock Bottom song to sing?</h2>
                    <p className={"a"}>I think that must be either One Way Or Another by Blondie, Toxic by Britney Spears or Sweet Child o' Mine. You hear that? It's an impossible question.</p>
                </div>
            ,
            img: Jackie,
            signature: JackieSig
        },
        {
            name: RasmusName,
            text:
                <div className={"member-text"}>
                    <h2 className={"q"}>Nice to meet you Rasmus, please tell us something about your insanely cool name!</h2>
                    <p className={"a"}>Sadly, my name isn't inspired by the awesome band that plays a song called ‘In The Shadows'. I’m actually named after this book called 'Rasmus På Luffen', go look it up it’s brilliant! 😄</p>
                    <h2 className={"q"}>Jackie told us a pretty vague story about you meeting through Instagram. But you all seem to be getting along pretty well. Is that just a coincidence then?</h2>
                    <p className={"a"}>Not really! The boys and I met during our time at the Haarlem Conservatory already. First Jackie got into contact with Mathijs, Renzo and Olaf, but they needed another guitarist for Rock Bottom. So I soon got a message from 	the boys, asking me for the band. They all managed to send me te same message at the exact same time haha. Of course I said yes. 😉 Later I met Esther and Jackie, two fantastic girls who have a great sense of humor as well.</p>
                    <h2 className={"q"}>What's your vibe when playing together with Rock Bottom?</h2>
                    <p className={"a"}>I love this band. No. Seriously, I LOVE this band.😍 The vibe and sound we have when playing together creates such a high energy and is so great to be a part of. It must be amazing to watch us play, but believe me, standing there on the stage with these beautiful human beings is something else as well!</p>
                </div>
            ,
            img: Rasmus,
            signature: RasmusSig
        },
        {
            name: MathijsName,
            text:
                <div className={"member-text"}>
                    <h2 className={"q"}>Well hello Mathijs! So you and Rasmus are both guitarists in Rock Bottom. Do you ever fight over who gets the sickest solo?</h2>
                    <p className={"a"}>We fight over solos all the time! We practice like maniacs and the one who gets it flawless at first gets to play it live. Hahaha, no kidding. We never really argue about it. Most of the time we have the same idea about what works best for the total sound of the band. So before rehearsals, we discuss per song who plays what and also who does the solo. Good vibes only, you know it. 😛</p>
                    <h2 className={"q"}>Well, good for you. Which solo is your personal favorite?</h2>
                    <p className={"a"}>Favorite guitar solo… That’s a hard one. I really like the way we play Purple Rain and how Rasmus and I share that solo. It's a moment! But also the Guns ’n Roses and Bon Jovi solos. I guess I’m just a sucker for those 80’s rock hits haha.</p>
                    <h2 className={"q"}>Who isn't really? Now something completely off-topic, but is that your real hair color?</h2>
                    <p className={"a"}>I’m gonna clear the air right now and then I’m never answering this question again haha. Yes, I’m a natural ginger. Yes, I was blessed with two of the ginger genes. Yes, I get sunburned easily. Yes, gingers do have souls. No, gingers do not plan to take over the world (or do we?). 👀</p>
                </div>
            ,
            img: Mathijs,
            signature: MathijsSig
        },
        {
            name: EstherName,
            text:
                <div className={"member-text"}>
                    <h2 className={"q"}>Damnnn Esther, you're looking hot! What do you play?</h2>
                    <p className={"a"}>I play the keys! Also I get to shout some lyrics. 😉</p>
                    <h2 className={"q"}>Very cool. Was it always your dream to be in a cover-band?</h2>
                    <p className={"a"}>It's funny that you ask. Not really. I just like the concept of Rock Bottom 	a lot. So it grew on me! Let me explain to you what playing cover songs can be like: One time I did a cover gig with a friend of mine. We played some really nice songs on this private event - real sweet, nothing crazy - yet the woman from the venue comes up to us in between sets asking if we can turn down the volume. She thought the music was too loud. 😅 Now that is something that's unlikely to happen with Rock Bottom and its audience. There's no need to hold back.</p>
                    <h2 className={"q"}>What's your Rock Bottom goosebump moment?</h2>
                    <p className={"a"}>I'm a total sucker for these people. Every rehearsal gets me so excited; I'm blown away by the talent of these guys 'n girl. What Jackie can do with her voice is insane... Oh and of course the photo shoot! Seeing everyone lean into their alter-ego was just so much fun and it made our vision come alive after months of preparation. Amazing day.</p>
                </div>
            ,
            img: Esther,
            signature: EstherSig
        },
        {
            name: OlafName,
            text:
                <div className={"member-text"}>
                    <h2 className={"q"}>The bassist! Olaf, what bassist do you channel the most in Rock Bottom?</h2>
                    <p className={"a"}>All of them! Bass seems straight forward but each decade and each band has its own flair and character. I add my own flavor to it but I try to stay true to the song always!</p>
                    <h2 className={"q"}>I hear you're from the Dutch island Texel? That's pretty cool. Can you tell us what it's like, growing up on an island?</h2>
                    <p className={"a"}>Nothing beats the feeling of having the ocean near at all times. And working in the summer sun and meeting new people from all over the country at your own doorstep is a great experience.</p>
                    <h2 className={"q"}>When did you personally hit Rock Bottom in life?</h2>
                    <p className={"a"}>Moving to the big city on my own was really hard at first and it took me a while to get adjusted to a new life. Eventually I got out there and really connected with some incredible people but it was a big step to take.</p>
                    <h2 className={"q"}>Let's end this with your favorite joke.</h2>
                    <p className={"a"}>I’ve set my WiFi password to “itsonthefridge”.</p>
                    <h2 className={"q"}>Aw mannn, that's just really bad haha, get out of here! 😂</h2>
                </div>
            ,
            img: Olaf,
            signature: OlafSig
        },
        {
            name: RenzoName,
            text:
                <div className={"member-text"}>
                    <h2 className={"q"}>And last but not least! Renzo, I hear you're already famous?</h2>
                    <p className={"a"}>Well not quite sure if I’m famous haha, but I’ve had the chance to do a lot of cool stuff already, that's for sure! I have my own rock band with whom I recorded several albums and played in many festivals and clubs. We toured the UK, Europe and Asia! Besides that I’ve had the honor to play with some well-known Dutch artists which I’m also very grateful for.</p>
                    <h2 className={"q"}>Ok, that's really cool and all, but tell me something awkward for once!</h2>
                    <p className={"a"}>Haha, I actually have a good one! Some time ago I was in the studio, having pasta for dinner. After we finished, it turned out the pasta was served with a ladle that was used earlier that day to unclog the toilet. Really very awkward and downright disgusting hahaha. 😂</p>
                    <h2 className={"q"}>You're the drummer of this kick-ass project. What do you like most about Rock Bottom?</h2>
                    <p className={"a"}>What I like most about this kick-ass project is that it’s totally kicking ass! I mean all these songs we’re playing are the songs I grew up listening to and getting inspired by. And to now play these songs with some very talented musicians and in that way inspire other people is just perfect! And getting to blow the roof off a venue every time we play is definitely a big bonus. 🔥</p>
                </div>
            ,
            img: Renzo,
            signature: RenzoSig
        },
    ];

        return (
            <div className={"page"}>
                <Navbar />
                <ToTop />
                <div id={"band-members-page"}>
                    {memberInfo.map((member) => {
                        return (
                            <BandMemberBio
                                key={member.name}
                                bandMemberImg={member.img}
                                name={member.name}
                                text={member.text}
                                signatureImg={member.signature}
                            />
                        )
                    })}
                </div>
                <Footer/>
            </div>
        )

}

export default BandMembers;
