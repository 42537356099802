import Navbar from "../../Components/Navbar/Navbar";
import Footer from "../../Components/Footer/Footer";
import {useState, useRef} from "react";
import CloseIcon from '@mui/icons-material/Close';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';

const Gallery = () => {

    function importAll(r) {
        let images = {};
        // eslint-disable-next-line array-callback-return
        r.keys().map((item, index) => { images[item.replace('./', '')] = r(item); });
        return images;
    }

    const images = importAll(require.context('../../Assets/Gallery', false, /\.(png|jpe?g|svg)$/));

    const imgArr = Object.keys(images).map(function(key){ return images[key]});

    const modalImg = useRef("null");
    const [modal, setModal] = useState(false);
    const [tempImg, setTempImg] = useState("");
    const displayImg = (image) => {
        setTempImg(image);
        setModal(!modal);
    }

    const nextImg = (image) => {
        for (let i = 0; i < imgArr.length; i++) {
            if (imgArr[i] === image) {
                if (i + 2 > imgArr.length) {
                    setTempImg(imgArr[0])
                } else {
                    setTempImg(imgArr[i + 1])
                }
            }
        }
    }

    const prevImg = (image) => {
        for (let i = 0; i < imgArr.length; i++) {
            if (image === imgArr[i]) {
                if (i - 1 < 0) {
                    setTempImg(imgArr[imgArr.length - 1])
                } else {
                    setTempImg(imgArr[i - 1])
                }
            }
        }
    }

    return (
        <div className={"page"}>
            <Navbar />
            <div className={modal? "modal open" : "modal"}>
                <NavigateNextIcon id={"gallery-prev"} onClick={() => prevImg(tempImg)} />
                <img ref={modalImg} className={"modal-img"} src={tempImg} alt={"Active gallery item"} />
                <div id={"modal-img-credit-container"}>
                    <p className={"modal-img-credit"}>Photo by Rachel Ecclestone <a className={"credit-link"} href={"https://www.instagram.com/rachelecclestone/?hl=en"} target={"_blank"} rel={"noreferrer"}>@rachelecclestone</a></p>
                    <p className={"modal-img-credit"}>Concept & Styling by Jackie Lou and Stephanie Rose <a className={"credit-link"} href={"https://www.instagram.com/jackieloumusic/?hl=en"} target={"_blank"} rel={"noreferrer"}>@jackieloumusic</a> <a className={"credit-link"} href={"https://www.instagram.com/stephanierose.___/?hl=en"} target={"_blank"} rel={"noreferrer"}>@stephanierose.___</a></p>
                    <p className={"modal-img-credit"}>Hair by Linsey Maria <a className={"credit-link"} href={"https://www.instagram.com/hairbylinseymaria/?hl=en"} target={"_blank"} rel={"noreferrer"}>@hairbylinseymaria</a></p>
                    <p className={"modal-img-credit"}>Make up by Lydia Thann <a className={"credit-link"} href={"https://www.instagram.com/lydiathann/?hl=en"} target={"_blank"} rel={"noreferrer"}>@lydiathann</a></p>
                </div>
                <CloseIcon id={"gallery-close"} onClick={() => setModal(!modal)} />
                <NavigateNextIcon id={"gallery-next"} onClick={() => nextImg(tempImg)} />
            </div>
            <div id={"gallery-page"}>
                {imgArr.map((image, index) => {
                    return (
                        <img className={"gallery-img"} src={image} key={index} alt={"Gallery item"} onClick={() => displayImg(image)}/>
                    )
                })}
            </div>
            <Footer />
        </div>
    )

}

export default Gallery;