import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import {useEffect, useRef, useState} from "react";

const ToTop = () => {

    const showOnPx = 150;
    const backToTopButton = useRef(null);
    const scrollContainer = () => {
        return document.documentElement || document.body;
    };
    const [scroll, setScroll] = useState(scrollContainer().scrollTop > showOnPx);

    const updateMedia = () => {
        setScroll(scrollContainer().scrollTop > showOnPx);
    };

    useEffect(() => {
        window.addEventListener("scroll", updateMedia);
        return () => window.removeEventListener("scroll", updateMedia);
    });

    const goToTop = () => {
        document.body.scrollIntoView({
            behavior: "smooth"
        });
    };

    return (
        <div className={scroll ? "to-top-container visible" : "to-top-container hidden"}>
            <NavigateNextIcon ref={backToTopButton} onClick={goToTop} className={"back-to-top"}/>
        </div>
    )

}

export default ToTop;