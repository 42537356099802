import './App.css';
import { BrowserRouter, Routes, Route} from "react-router-dom";
import Home from "./Pages/Home/Home";
import BandMembers from "./Pages/BandMembers/BandMembers";
import About from "./Pages/About/About";
import Events from "./Pages/Events/Events";
import Gallery from "./Pages/Gallery/Gallery";
import Contact from "./Pages/Contact/Contact";
import Listen from "./Pages/Listen/Listen";

function App() {
  return (
      <BrowserRouter>
        <Routes>
          <Route path="/" element={ <Home /> } />
          <Route path="/about" element={ <About /> } />
          <Route path="/band-members" element={ <BandMembers /> } />
          <Route path="/events" element={ <Events /> } />
          <Route path="/gallery" element={ <Gallery /> } />
            <Route path="/contact" element={ <Contact /> } />
            <Route path="/listen" element={ <Listen /> } />
        </Routes>
      </BrowserRouter>
  );
}

export default App;
