import Navbar from "../../Components/Navbar/Navbar";
import Footer from "../../Components/Footer/Footer";
import EventsHeader from "../../Assets/Titles/Pages/04-EVENTS.png"

const Events = () => {

    return (
        <div className={"page"}>
            <Navbar />
            <div id={"events-page"}>
                <img id={"events-page-header"} src={EventsHeader} alt={"Events page header"}/>
                <div id={"events-content-container"}>
                    <h2 className={"events-content"}><a className={"event-link"} href={"https://pikafestival.nivon.nl/"} target={"_blank"} rel={"noreferrer"}>28/05/2023 - Pika Festival (NL)</a></h2>
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default Events;