import HomeImg from "../../Assets/Home/Homepage horizontal no logo1.jpg"
import HomeImgMobile from "../../Assets/Home/mob-home.jpeg"
import SloganImg from "../../Assets/Logo/ROCK BOTTOM + SLOGAN/ROCK BOTTOM + SLOGAN NO BACKGROUND.png"
import Navbar from "../../Components/Navbar/Navbar";
import Footer from "../../Components/Footer/Footer";
import {useEffect, useState} from "react";
import Button from "../../Components/Button/Button"
import {Link} from "react-router-dom";

const Home = () => {

    const [isHomeMobile, setHomeMobile] = useState(window.innerWidth < 450);

    const updateMedia = () => {
        setHomeMobile(window.innerWidth < 450);
    };

    useEffect(() => {
        window.addEventListener("resize", updateMedia);
        return () => window.removeEventListener("resize", updateMedia);
    });

    return (
        <div className={"page"}>
            <Navbar />
            <div id={"home-page"}>
                <div id={"home-img-container"}>
                    {isHomeMobile ? <img id={"home-img"} src={HomeImgMobile} alt={"Band members"}/> : <img id={"home-img"} src={HomeImg} alt={"Band members"}/>}
                    <img id={"slogan-img"} src={SloganImg} alt={"Slogan"}/>
                </div>
                <div id={"home-content"}>
                    <h1 id={"home-title"}>Welcome to the official website of coverband Rock Bottom. Have a look around!</h1>
                    <p id={"home-text"}>If you have any questions regarding bookings, pricing or repertoire, contact us by clicking on the shiny button below.</p>
                    <Button content={<Link to={"/contact"}>Contact</Link>}/>
                </div>
            </div>
            <Footer />
        </div>
    )

}

export default Home;