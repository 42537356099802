import Navbar from "../../Components/Navbar/Navbar";
import Footer from "../../Components/Footer/Footer";
import AboutImg from "../../Assets/About/Rock Bottom- © rachelecclestone -_RAC8052.jpg"
import AboutHeader from "../../Assets/Titles/Pages/02-ABOUT.png"

const About = () => {

    return (
        <div className={"page"}>
            <Navbar />
            <div id={"about-page"}>
                <img id={"about-img"} src={AboutImg} alt={"Band members"}/>
                <div id={"about-content-container"}>
                    <p className={"about-content"}>
                        Are you ready to smell Kurt Cobain's shirt, sing to brilliant melodies by king Freddy Mercury and feel the power of AC/DC? Rock Bottom is here for you and we are ready to party! Let yourself go on a journey with front-woman Jackie Lou and her hot crew as they make sure you live through all decades of rock, punk and grunge; Rock Bottom is the cover-band you are looking for.
                    </p>
                    <p className={"about-content"}>
                        And let me tell you something, musicians like these are hard to find. Bassist Olaf Zegeren will give you serious chills with his timing, while Drummer Renzo Rijn makes sure the whole venue starts dancing like maniacs. Bon Jovi sounds like a real Bon Jovi because of Esther Jacobs's endless flair on the keys. Front-woman Jackie Lou will blow you away with her killer voice. And then there's not one, but two phenomenal guitarists who bring the juice and take the stage like no other: Mathijs van der Voorn and Rasmus Bisschop. Remember those names, baby..
                    </p>
                    <p className={"about-content"}>
                        Tasteful, yet properly tearing down the house, these six young bloods serve you the hottest rock songs in history. They are true-hearted rock fanatics, dressed like a million bucks and they're seriously devoted to the sound and esthetic you should expect from a proper rock-hit cannon. Their legend-like performance has been sculpted by various prestigious conservatories and performing arts programs. Rock Bottom can be booked for your festival, wedding or event now.
                    </p>
                    <p className={"about-content"}>
                        Experience the ultimate rock concert you've been craving!
                    </p>
                </div>
                <img id={"about-page-header"} src={AboutHeader} alt={"About page header"}/>
            </div>
            <Footer />
        </div>
    )

}

export default About;