import {useRef, useState} from "react";
import {gsap} from "gsap";

const BandMemberBio = (props) => {

    const memberImg = useRef(null);
    const signatureImg = useRef(null);
    const [isHovered, setIsHovered] = useState(true)
    const hoverTimeline = gsap.timeline()
    const imgHover = () => {
        setIsHovered(!isHovered);
        isHovered ?
            hoverTimeline
                .to([memberImg.current], {
                    scale: 1.025,
                    opacity: 0.8,
                    duration: 0.3
                })
                .to([signatureImg.current], {
                    opacity: 1,
                    duration: 0.5
                })
            :
            hoverTimeline
                .to([memberImg.current], {
                    scale: 1,
                    opacity: 1,
                    duration: 0.5 })
                .to([signatureImg.current], {
                    opacity: 0,
                    duration: 0.5
                 })
    }

    return (
        <div className={"member-bio-container"}>
            <div className={"member-img-container"}>
                <img ref={memberImg} className={"member-img"} src={props.bandMemberImg} alt={"Band member"} onMouseEnter={imgHover} onMouseLeave={imgHover}/>
                <img ref={signatureImg} className={"member-signature"} src={props.signatureImg} alt={"Band member signature"} />
            </div>
            <div className={"member-bio"}>
                <img className={"member-name"} src={props.name} alt={"Band member name"}/>
                <div className={"member-text-container"}>{props.text}</div>
            </div>

        </div>
    )

}

export default BandMemberBio