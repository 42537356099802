import FacebookIcon from '@mui/icons-material/Facebook';
import YouTubeIcon from '@mui/icons-material/YouTube';
import InstagramIcon from '@mui/icons-material/Instagram';
import EmailIcon from '@mui/icons-material/Email';

const Footer = () => {

    return (
        <div id={"footer"}>
            <div id={"footer-content-left"}>
                <a className={"footer-link footer-icon"} href="mailto:info@rockbottomband.nl"><EmailIcon/></a>
                <a className={"footer-link footer-icon"} href="https://www.instagram.com/rockbottom.band/" target={"_blank"} rel={"noreferrer"}><InstagramIcon/></a>
                <a className={"footer-link footer-icon"} href="https://www.facebook.com/coverbandrockbottom" target={"_blank"} rel={"noreferrer"}><FacebookIcon/></a>
                <a className={"footer-link footer-icon"} href="https://www.youtube.com/channel/UC5XCt50xW1yBDuIKKPmORYw" target={"_blank"} rel={"noreferrer"}><YouTubeIcon/></a>
            </div>
            <div id={"footer-content-right"}>
                <p className={"footer-credit"}>© Rock Bottom 2022</p>
            </div>
        </div>
    )

}

export default Footer;